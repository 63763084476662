import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function aia(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/aia-case2.png'
  const thumbAltText = 'aia case study'
  const description = 'The American Institute of Architects (AIA) works to advance the United States quality of life and protect the public’s health, safety, and welfare, as it has done for 160 years. We helped them navigate through challenging deadlines for critical functionalities, important technical decisions, a loosely defined agile development process, and worked with their leadership to bring a mature development capability to their software practice.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks demonstrated their capabilities to deliver trust across the entire organization'

  return (
    <Layout
      location={props.location}
      title='AIA'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo cc-logo-white'>
                  <img src='/images/brands/aia-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/aia-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Architecture
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  +2 years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')} />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            The American Institute of Architects (AIA) works to advance the United States quality of life and protect the public’s health, safety, and welfare, as it has done for 160 years. Each day AIA members across the country and throughout the world create the places where people live and learn, work, and play. All the while, architects work with clients and allied design professionals and construction partners to improve the built environment in a $1 trillion sector that accounts for almost 6% of the US economy.
          </p>
          <p>
            Back in 2002 in response to the ongoing climate emergency, Architecture2030 came to life with the mission to rapidly transform the global built environment from the major contributor of greenhouse gas emissions to a central part of the solution to the climate crisis. In strong collaboration, AIA launched the 2030 Commitment to support the initiative and transform the practice of architecture in a way that is holistic, firm-wide, project based, and data-driven. A key component to it is Design Data Exchange (DDx), a national framework they provide to architecture firms with simple metrics and a standardized reporting format for measuring progress. The confidential, easy-to-use DDx application lets them pinpoint best practices and anonymously compare project performance across the 2030 portfolio.
          </p>
          <p>
            Fast forward to 2019 there were a lot of lessons learned from the legacy site and a refresh was in order, and given the great work previously done by our team they commanded us to do that. When AIA asked for our help with the project, the situation was very delicate. The project that had been started by other team was delayed and deadlines weren’t met, and that challenged everyone’s trust.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            When taking ownership of the project, we quickly realized there was a lot of work to be done. We brought additional engineers and our agile coach in, assembling a well balanced and dedicated team to increase the efforts. Right from the beginning, we started working closely with stakeholders and product owners to define requirements and scope, something that was still not clear to everyone involved. Iterating over the vision and what was needed, making sure the whole team was on the same page, was crucial for a successful launch. With everyone’s help, the team was finally able to set a roadmap and commit to a target date.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "I had a lot of obligations to making the organization successful when I first hired WyeWorks, and they have enabled my success to deliver for the needs of the organization. Contracting with their team has proven to be an excellent decision for us. With their help we move faster and continually improve our processes. I happily came to realize that they practice what they preach."
              </div>
              <div className='quote-block__provider-title'>
                Benjamin Hollenbeck
                <br />
                Sr. Director of Product
              </div>
            </div>
          </div>
          <p>
            A couple of iterations along the way, we decided to make some changes to the development process to make it more efficient, resulting in an increase of the team’s velocity two-fold. We also pushed hard to improve the usability, making the end result better. After months of hard work towards the goal the project achieved the deadline, and what was agreed by stakeholders was delivered. That was a big accomplishment, not only for the sake of the project itself but also it was a big boost towards the path of building trust on what engineering is capable of across the entire organization.
          </p>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/aia-case3.jpg' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            We’ve built a partnership that will last for years to come. We helped improve the agile delivery process to the point that sets the teams up for success, as well as improved productivity overall, and we are well on our road to continuously deploy new versions of the platform.
          </p>
          <p>
            Communication flows better than before, and the teams are more aligned and empowered to achieve the organization’s goals. Team values are now ingrained into the daily work, as we are helping leaders on the path of transforming the AIA software development practice to a more engaging and productive one.
          </p>
          <p>
            But most importantly, stakeholders are once again happy and have renewed their faith in what can be achieved now with the help of our team.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['packlane', 'prisma']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
